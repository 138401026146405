import React, {useCallback} from "react";
import {Heading} from "../UI/Typography";
import Dash from "../UI/Dash";
import Form from "../Form";
import {FormattedMessage} from "react-intl";
import DropBg from '../UI/DropBg';
import DropBlurVioletBg from "../../images/jobs/DropBlurVioletBg.svg";
import Header from "../Header";
import * as Yup from "yup";
import {formEndpoints} from "../../utilities/constants"

const SingleInternshipPage = ({IntlTextObj, intl}) => {
    const {
        role,
    } = IntlTextObj;

    const formFields = [
        {
            name: 'name',
            label: 'jobs.form_name',
            required: true,
            type: 'input-text',
        },
        {
            name: 'surname',
            label: 'jobs.form_lastname',
            required: true,
            type: 'input-text',
        },
        {
            name: 'email',
            label: 'jobs.form_email',
            required: true,
            type: 'input-email',
        },
        {
            name: 'phone',
            label: 'jobs.form_phone',
            required: true,
            type: 'input-text',
        },
        {
            name: 'city',
            label: 'jobs.form_city',
            type: 'input-text',
        },
        {
            name: 'role',
            label: 'jobs.form_area',
            placeholder: 'jobs.form_area_placeholder',
            required: true,
            type: 'select',
            options: [
                {label: intl.formatMessage({id: 'jobs.development'}), value: 'Development Internship'},
                {label: intl.formatMessage({id: 'jobs.design'}), value: 'Design Internship'},
                {label: intl.formatMessage({id: 'jobs.marketing'}), value: 'Marketing Internship'},
                {label: intl.formatMessage({id: 'jobs.data_analysis'}), value: 'Data Analysis Internship'}
            ]
        },
        {
            name: 'fileCVUrl',
            label: 'jobs.form_cv_letter',
            required: true,
            type: 'dropzone',
        },
        {
            name: 'filePortfolioUrl',
            label: 'jobs.form_portfolio',
            type: 'dropzone',
        },
        {
            name: 'portfolioUrl',
            label: 'jobs.form_url_portfolio',
            type: 'input-text',
        },
        {
            name: 'linkedinUrl',
            label: 'jobs.form_linkedin',
            type: 'input-text',
        },
        {
            name: 'languages',
            label: 'jobs.form_languages',
            required: true,
            type: 'input-text',
        },
        {
            name: 'origin',
            label: 'jobs.form_job',
            type: 'input-text',
        },
        {
            name: 'startAt',
            label: 'jobs.form_start',
            type: 'input-text',
        },
        {
            name: 'privacy',
            label: 'jobs.form_personal_data',
            required: true,
            type: 'checkbox',
        }
    ];

    const validationSchema = Yup.object({
        name: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        surname: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        email: Yup.string().email(intl.formatMessage({id: 'contactus.error_bad_email'})).required(intl.formatMessage({id: 'contactus.error_required'})),
        phone: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        city: Yup.string(),
        role: Yup.object().nullable().required(intl.formatMessage({id: 'contactus.error_required'})),
        fileCVUrl: Yup.object().nullable().required(intl.formatMessage({id: 'contactus.error_required'})),
        filePortfolioUrl: Yup.object().nullable(),
        portfolioUrl: Yup.string().url(intl.formatMessage({id: 'contactus.error_bad_url'})),
        linkedinUrl: Yup.string().url(intl.formatMessage({id: 'contactus.error_bad_url'})),
        languages: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        origin: Yup.string(),
        startAt: Yup.string(),
        privacy: Yup.boolean().required(intl.formatMessage({id: 'contactus.error_required'})).oneOf([true], intl.formatMessage({id: 'contactus.error_required'}))
    });

    const formDataToSend = useCallback((values) => ({
        ...values,
        fileCVUrl: values?.fileCVUrl?.url,
        filePortfolioUrl: values?.filePortfolioUrl?.url,
        role: values?.role?.value || role,
        from: location.href
    }), [])

    return <div>
        <Header siteTitle={"kromin"} headerStyle={'light'} hideLangs={false}/>
        <DropBg src={DropBlurVioletBg} top={"0%"} hideOnTablet={true} noOverflow={true}/>
        <div className="section-padding" style={{paddingTop: 228}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <Heading level={'2'}>
                            <FormattedMessage id={role}/>
                        </Heading>
                        <Dash margin={'24px auto'}/>
                        {/*<span className="job-form-subtitle"><FormattedMessage id="jobs.login_linkedin"/></span>*/}
                    </div>
                    {/*<div className="col-12 d-flex justify-content-center">
                        <LinkedinButton/>
                    </div>*/}
                </div>
                <div className="row">
                    <div className="col-12">
                        <Form formFields={formFields} ctaText="jobs.btn_action" validationSchema={validationSchema} endpoint={formEndpoints.CONTACT_US_CV}
                              successMessage="jobs.form_submit_success_text" successTitle="contactus.form_submit_success"
                              formDataToSend={formDataToSend}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SingleInternshipPage;
